@media print {
  .noprint {
    display: none;
  }

  @page {
    size: A4;
  }
}

@media screen {
  .printOnly {
    display: none;
  }
}